import React from "react"; 
 
import Banner from "components/Landpage/Banner";
import HowWorks from 'components/Landpage/HowWorks'
import MoreInfos from 'components/Landpage/MoreInfos'
import CommomCases from 'components/Landpage/CommomCases'
import KnowMore from 'components/Landpage/KnowMore'
import LittleMore from 'components/Landpage/LittleMore' 

import ContainerLandpage from "containers/Landpage";
import { Col, Container, Row } from "reactstrap";
import { PageContent } from "./styled";

export default function Terms(){ 
 
    return ( 
        <ContainerLandpage> 
            <Container>
                <Row>
                    <Col>
                        <PageContent>

                                <h1>Terms and conditions of use</h1>

                                <p>
                                The use of the site implies full acceptance of the general conditions of use described below.
                                </p>
                                <p>
                                These conditions of use may be modified or supplemented at any time, without notice, so users of the site are invited to consult them regularly. Wall Street Web also reserves the right to assign, transfer, without notice the rights and / or obligations of these Terms and legal notices.
                                </p>
                                <p>
                                By continuing to use the site, the user acknowledges accepting the changes to the general conditions that have occurred.
                                </p>
                                <b>Description of the services provided</b>
                                <p>
                                The purpose of the www.pubclickmarketing.com website is to provide information on all of the company's activities. The owner of the site strives to provide information as accurate as possible. However, it can not be held responsible for omissions, inaccuracies and deficiencies in the update, whether by itself or by third party partners who provide this information.
                                </p>
                                <p>
                                All the information offered is given as an indication, is not exhaustive, and is subject to change. They are given subject to changes that have been made since they were put online.
                                </p>
                                <p>
                                Interactive spaces are available to users. The owner of the site reserves the right to delete, without prior notice, any content posted in this space that would contravene the legislation applicable in Switzerland, in particular the provisions relating to data protection. If necessary, the owner of the site also reserves the right to question the civil and / or criminal liability of the user, in particular in the event of a racist, abusive, defamatory or pornographic message, regardless of the medium used (text, photography ...).
                                </p>

                                <b>Limits of liability</b>
                                <p>
                                    The www.pubclickmarketing.com site can not be held responsible for typographical errors or inaccuracies appearing on the service, or for any damage suffered as a result of its use. The user remains responsible for his equipment and its use, in the same way he alone bears the direct or indirect costs following his connection to the Internet.
                                </p>
                                <p>
                                    The user disclaims the responsibility of www.pubclickmarketing.com for any damage that he may suffer or cause to be suffered, directly or indirectly, as a result of the services offered. Only the responsibility of the user is engaged by the use of the service offered and it expressly releases the site www.pubclickmarketing.com from any responsibility vis-à-vis third parties.
                                </p>
                                <p>
                                    The www.pubclickmarketing.com site contains a number of hypertext links to other sites (partners, information ...) set up with the authorization of the owner of the site. However, the owner of the site does not have the possibility to check the content of the sites thus visited and therefore declines any responsibility for this fact when the possible risks of illegal content.
                                </p>

                                <b>Intellectual Property</b>
                                <p>
                                    The owner of the site is the owner of the intellectual property rights or holds the rights of use on all the elements accessible on the site, in particular the texts, images, graphics, logo, icons, sounds, software…
                                </p>
                                <p>
                                    Any reproduction, representation, modification, publication, total or partial adaptation of the elements of the site, whatever the means or process used, is prohibited without prior written authorization to the email: support@wallstreetweb.net.
                                </p>
                                <p>
                                    Any unauthorized use of the site or any of these elements it contains will be considered as constituting an infringement and prosecuted in accordance with the provisions of articles L.335-2 and following of the Intellectual Property Code.
                                </p>

                                <b>Protection of property and people - management of personal data:</b>
                                www.pubclickmarketing.com collects personal information about the user only for the need of certain services offered by the site www.pubclickmarketing.com. The user provides this information with full knowledge of the facts, in particular when he enters it himself. It is then specified to the user of the site www.pubclickmarketing.com the obligation or not to provide this information.

                                <p>
                                Any user has a right of access, rectification, deletion and opposition to personal data concerning him. To exercise this right, send your request to www.pubclickmarketing.com by email: email from the webmaster or by making his written and signed request, accompanied by a copy of the identity document with signature of the holder of the document, specifying the address to which the response must be sent.
                                </p>
                                <p>
                                No personal information of the user of the site www.pubclickmarketing.com is published without the knowledge of the user, exchanged, transferred, assigned or sold on any medium whatsoever to third parties. Only the assumption of redemption of the site www.pubclickmarketing.com and its rights would allow the transmission of said information to the potential purchaser who would in turn be given the same obligation to store and modify data with respect to the user of the site www.pubclickmarketing.com.
                                </p>
                                <p>
                                The www.pubclickmarketing.com site is declared to the CNIL under number 0.
                                </p>
                                <p>
                                Databases are protected by the provisions of the Act of 1 July 1998 transposing Directive 96/9 of 11 March 1996 on the legal protection of databases.
                                </p>
                                <b>Hypertext links and cookies:</b>
                                <p>
                                The user is informed that during his visits to the site www.pubclickmarketing.com, one or more cookies may be automatically installed on his computer. A cookie is a small file, which does not allow the identification of the user, but which records information relating to the navigation of a computer on a site. The data thus obtained are intended to facilitate subsequent navigation on the site, and are also intended to allow various measures of attendance.
                                </p>
                                <p>
                                The configuration of the browser software makes it possible to inform of the presence of cookies and possibly to refuse in the manner described at the following address: www.cnil.fr
                                </p>
                                <p>
                                Refusal to install a cookie may result in the impossibility of accessing certain services. The user can, however, configure his computer as follows, to refuse the installation of cookies:
                                </p>
                                <p>
                                In Internet Explorer: tool tab / internet options. Click Privacy and choose Block all cookies. Validate on Ok.
                                In Netscape: Edit / Preferences tab. Click Advanced and choose Disable Cookies. Validate on Ok.
                                </p>
                                
                                <b>Applicable law and jurisdiction:</b>
                                <p>
                                    Any dispute in connection with the use of the www.pubclickmarketing.com site is subject to Swiss law. The user and www.pubclickmarketing.com agree to submit to the exclusive jurisdiction of the Swiss courts in the event of a dispute.
                                </p>

                                <b>Legal notice</b>
                                <p>
                                    Publisher of the site
                                    Wall Street Web
                                    Chemin J.-Ph.-de-Sauvage 37
                                    CH-1219 Chatelaine
                                    Switzerland
                                    Commercial Register of the Canton of Geneva: CH-660.1.047.015-8
                                    Company identification number: CHE-155.503.825
                                </p>
                                <b> Editorial Manager </b>

                                <p>
                                    Jacky Cally
                                    Wall Street Web
                                    support@wallstreetweb.net
                                    +41 78 752 86 56
                                    Host
                                    Exoscale
                                </p>


                                <p>
                                    Boulevard de Grancy 19A
                                    1006 – Lausanne
                                    Switzerland
                                    www.exoscale.com
                                </p>

                        </PageContent>
                    </Col>
                </Row>
            </Container>

        </ContainerLandpage>
    );
}