import styled from 'styled-components' 

export const Content = styled.div.attrs({ 
})`          
    overflow:hidden;
`;
 

export const PageContent = styled.div.attrs({ 
})`          
    padding:105px 0 32px;
    font-size: 16px;
`;
 