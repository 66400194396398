import React from "react"; 
 
import Banner from "components/Landpage/Banner";
import HowWorks from 'components/Landpage/HowWorks'
import MoreInfos from 'components/Landpage/MoreInfos'
import CommomCases from 'components/Landpage/CommomCases'
import KnowMore from 'components/Landpage/KnowMore'
import LittleMore from 'components/Landpage/LittleMore' 

import ContainerLandpage from "containers/Landpage";
import { Col, Container, Row } from "reactstrap";
import { PageContent } from "./styled";

export default function Policy(){ 
 
    return ( 
        <ContainerLandpage>    
            
            <Container>
                <Row>
                    <Col>
                        <PageContent>

                            <p>
                                <b>Preamble</b>
                                By validating his payment, the Customer declares to accept without reservation the entirety of these general conditions of sale.
                            </p>
                            <p>
                                <b>Article 1 - Purpose</b>
                                The purpose of this contract is the online payment of invoices to Wall Street Web from the website publickmarketing.
                            </p>
                            <p>
                                <b>Article 2 - Formulas - Duration</b>
                                The Customer pays for one of the services defined in the terms of his contract established in advance and will benefit during the contractual period from access to the service and according to the rate in force on the day of the order.
                            </p>
                            <p>
                                <b>Article 3 - Rates and methods of payment</b>
                                The rates corresponding to the realization of a service or access to an online service.
                            </p>
                            <p>
                                To get an account and start paying your bills, you must already be a customer of a service offered by Wall Street Web, in which case contact us to get your credentials.
                            </p>
                            <p>
                                Several methods of payment are proposed:
                            </p>
                            <p>
                                Credit card according to a secure procedure (SSL)
                                PostFinance
                                Payment slip
                                Article 4 - Use of the confidential code
                                The username and password allowing the Customer to identify himself and connect to a part of the Site allowing him to pay his invoices. They can only be changed at the request of the Client or at the initiative of Wall Street Web.
                                The Customer is fully responsible for the use of personal identification information concerning him. The Customer undertakes to keep them secret and not to disclose them in any form whatsoever.
                                Any transaction carried out using the Customer's username and password is deemed to have been carried out by the Customer.
                            </p>
                            <p>

                                Any other use is subject to the prior and express authorization of Wall Street Web.
                            </p>
                            <p>

                                In the event of loss or theft of one of the personal identification elements concerning him, the Customer must choose a new password and a new login and notify Wall Street Web by mail or e-mail to the following address: support@wallstreetweb.net.
                            </p>
                            <p>

                            Article 5 - Validity of electronic exchanges
                            The Customer acknowledges the validity and probative value of the electronic exchanges and records made by publickmarketing and accepts that said records receive the same probative value as a handwritten signed writing.
                            </p>
                            <p>

                            Article 6 - Termination
                            In the event of a breach by the Customer of one of its essential obligations, publickmarketing. reserves the right to immediately and automatically suspend the provision of the Service and/or terminate this contract after a formal notice that has remained without effect for one month or, without notice.
                            </p>
                            <p>

                            In the event of early termination, publickmarketing will under no circumstances reimburse the sums paid in whole or in part by the customer.
                            </p>
                            <p>

                            Article 7 - Applicable law and competent courts
                            This contract is subject to Swiss law. In the event of a dispute concerning the application or interpretation of this contract, the parties agree to seek an amicable solution. Otherwise, the Swiss courts will have sole jurisdiction.
                            </p>

                        </PageContent>
                    </Col>
                </Row>
            </Container>
         
        </ContainerLandpage>
    );
}