import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 

import Item from './Item'

import {
    MoreTitle,
    MoreText,
    KnowContent
} from './styled' 
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function KnowMore(){ 
    
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
    const doctors = [
        {
            color:'bronze',
            title: 'Basic Plan',
            price: '89,90',
            details: [
                { text:'Classified ads for 1 week' },
                { text:'Link in forums (1x per week)' },
                { text:'Basic SEO' },
                { text:'Email support' },
            ],
            label:"Buy now",
            action: () => navigate("buy?amount=89,90")
        },
        {
            color:'silver',
            title: 'Bronze Plan',
            price: '199,90',
            details: [
                { text:'Classified ads for 1 week' },
                { text:'Link in forums (1x per week)' },
                { text:'Premium SEO' },
                { text:'5/7d support by phone', bold:true },
            ],
            label:"Buy now",
            action: () => navigate("buy?amount=199,90")
        },
        {
            color:'gold',
            title: 'Premium Plan',
            price: '399,90',
            details: [
                { text:'Classified ads for 1 week' },
                { text:'Link in forums (2x per week)', bold:true },
                { text:'Premium SEO', bold:true },
                { text:'7/7 support by phone', bold:true },
            ],
            label:"Buy now",
            action: () => navigate("buy?amount=399,90")
        } 
    ]

    const options = [
        {
            color:'stell',
            title: 'Pay an invoice',
            subtitle: 'You have a specific contract with us and you want to pay your invoice directly on the internet? It\'s simple, easy and secure, click below and let yourself be guided.',
            details: [  ],
            label:"Pay my invoice",
            action: () => navigate("buy?amount=59,90")
        }
    ]

    
 
    return ( 
        <> 
            <KnowContent id="packs">
                <Container>
                    <Row>
                        <Col>
                            <MoreTitle>Different packs depending on your goals</MoreTitle>
                            <MoreText>Several formulas are available depending on your needs and objectives. Whether for classified ads, forums, SEO or even SEA, we accompany you in your approach.</MoreText>
                        </Col>
                    </Row> 
                    <Row>
                        {
                            doctors.map((item, key) => 
                                <Col key={key} md={{ size:4 }}> 
                                    <Item {...item} />
                                </Col>
                            )
                        }
                    </Row>  
                    <Row>
                        <Col md={{ size:3 }} />
                        {
                            options.map((item, key) => 
                                <Col key={key} md={{ size:6 }}> 
                                    <Item {...item} />
                                </Col>
                            )
                        }
                    </Row>  
                </Container> 
            </KnowContent>
        </>
    );
}