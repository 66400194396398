import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';

import {
    FooterContainer,
    FooterLogo,
    FooterTitle,
    FooterText,
    FooterLink,
    FooterLinkImage,
    FooterSection,
    FooterPhoneContent,
    FooterPhoneIcon,
    FooterCopyright,
    FooterMailIcon,
    FooterContent,
    FooterPhoneContainer,
    FooterCopy,
    FooterActionsContent
} from './styled' 

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from "components/Form/Button";

export default function Footer(){ 
 
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    return ( 
        <> 
            <FooterContainer id="contact">
                <FooterContent>
                    <Container>
                                {/* <FooterLogo /> */}
                                <FooterTitle>Contact us</FooterTitle>
                                <FooterText>Contact us for more information on our offers and for personalized quotes.<br />We are at your disposal from Monday to Friday from 9am to 7pm.</FooterText>
                                
                                
                                <FooterPhoneContainer>
                                    <FooterPhoneContent>
                                        <PhoneIphoneIcon color="white" sx={{ width: 50, height: 80 }} />
                                        <FooterLink>+41 78 777 99 99</FooterLink>
                                    </FooterPhoneContent> 
                                    <FooterPhoneContent>
                                        <MailOutlineIcon color="white" sx={{ width: 50, height: 80 }} />
                                        <FooterLink>support@pubclickmarketing.com</FooterLink>
                                    </FooterPhoneContent> 
                                </FooterPhoneContainer>
                                
                                <FooterCopy>
                                    © PubClickMarketing 2024
                                </FooterCopy>

                                <FooterActionsContent>
                                    <a href="terms"> CGU </a>
                                        /   
                                    <a href="policy"> CGV </a>
                                </FooterActionsContent>
                                
                    </Container>
                </FooterContent>
            </FooterContainer>
        </>
    );
}