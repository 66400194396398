import React, { useEffect, useMemo, useRef, useState } from "react"; 
 
import Banner from "components/Landpage/Banner";
import HowWorks from 'components/Landpage/HowWorks'
import MoreInfos from 'components/Landpage/MoreInfos'
import CommomCases from 'components/Landpage/CommomCases'
import KnowMore from 'components/Landpage/KnowMore'
import LittleMore from 'components/Landpage/LittleMore' 

import ContainerLandpage from "containers/Landpage";
import { Col, Container, Row } from "reactstrap";
import { BodyActions, BodyForm, BodyTerms, BodyTermsLink, HeaderContent, HeaderImage, HeaderInfos, HeaderInfosText, HeaderInfosTitle, PageContent } from "./styled";
import Button from "components/Form/Button";
import Core from "components/Form/Core";
import Check from "components/Form/Check";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Create } from "services/core";
import { toast } from "react-toastify";
import { exposeStrapiError } from "utils";

export default function Buy(){ 

    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);

    const navigate = to => history.push(`/${ to }`);

    const formRef = useRef()
    const [ accepted, setAccepted ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const [register, setRegister] = useState({})

    const formItems = useMemo(() => [
        {
            ref: 'firstname',
            placeholder: 'First name*',
            required: true,
            half: true,
            onBlur: () => updateRegister()
        },
        {
            ref: 'lastname',
            placeholder: 'Last name*',
            required: true,
            half: true,
            onBlur: () => updateRegister()
        }, 
        {
            ref: 'email',
            placeholder: 'Email address*',
            required: true,
            half: true,
            onBlur: () => updateRegister()
        }, 
        {
            ref: 'phone',
            placeholder: 'Phone',
            half: true,
            onBlur: () => updateRegister()
        }, 
        {
            ref: 'company',
            placeholder: 'Company',
            full: true,
            onBlur: () => updateRegister()
        }, 
        {
            ref: 'url',
            placeholder: 'URL of your site to reference',
            full: true,
            onBlur: () => updateRegister()
        }, 
        {
            type: 'money',
            ref: 'amount',
            placeholder: 'Amount*',
            disabled: true,
            required: true,
            full: true,
            onBlur: () => updateRegister()
        }
    ], [])

    const updateRegister = () => {
        const form = formRef?.current?.getForm(true)
        setRegister({ ...register, ...form })
    }

    const save = async () => {

        if(!accepted){
            toast.error(`Accept terms.`);
            return;
        }

        const form = formRef?.current?.getForm()
        if(!form || loading) return ;
        setLoading(true)
        const payload = {
            ...form
        }

        const amount = parseFloat(`${payload?.amount}`?.replace(",", "."))
        
        const result = await Create("stripe", {
            ...payload,
            name:"Dexsire Premium",
            amount: amount,
            price: parseInt(amount * 100)
        })
        
        setLoading(false)
        if(result && !exposeStrapiError(result) && result?.url){
            window.location.href = result?.url
        }

    }

    useEffect(() => {
        setRegister({ amount: urlParams.get('amount') })
    }, [ ])

    return ( 
        <ContainerLandpage>    
            
            <Container>
                <HeaderContent>
                    <HeaderImage />
                    <HeaderInfos>
                        <HeaderInfosTitle>Make a payment through Stripe</HeaderInfosTitle>
                        <HeaderInfosText>
                            <p>To send us a payment by Stripe, please fill out the form below.</p>
                            <p>You will then be redirected to Stripe, check the payment information and then choose your payment method as you are used to doing.</p>
                        </HeaderInfosText>
                    </HeaderInfos>
                </HeaderContent>
                <BodyForm>
                    <Core ref={formRef} formItems={formItems} register={register} />

                    <BodyTerms>
                        <Check label="En cochant cette case, vous acceptez " checked={accepted} onChange={() => setAccepted(!accepted)} />
                        <BodyTermsLink onClick={() => navigate("policy")}>nos CGV</BodyTermsLink> *
                    </BodyTerms>
                    <BodyActions>
                        <Button loading={loading} color={"secondary"} onClick={save}>Pay with Stripe</Button>
                    </BodyActions>
                </BodyForm>
            </Container>
         
        </ContainerLandpage>
    );
}