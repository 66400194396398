import React from "react"; 
 
import Banner from "components/Landpage/Banner";
import HowWorks from 'components/Landpage/HowWorks'
import MoreInfos from 'components/Landpage/MoreInfos'
import CommomCases from 'components/Landpage/CommomCases'
import KnowMore from 'components/Landpage/KnowMore'
import LittleMore from 'components/Landpage/LittleMore' 

import ContainerLandpage from "containers/Landpage";
import { Col, Container, Row } from "reactstrap";
import { IconContent, PageContent, Text, Title } from "./styled";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from "components/Form/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Cancel(){ 
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
 
    return ( 
        <ContainerLandpage>    
            
            <Container>
                <Row>
                    <Col>
                        <PageContent>
                            <IconContent>
                                <CheckCircleOutlineIcon color="stell" sx={{ height: 80, width: 80 }} />
                            </IconContent>
                            <Title centred>Purchase fail</Title>
                            <Text>Your payment cant be confirmed successfully, try again later</Text>
                            <Text>
                                <Button color="secondary" onClick={() => navigate("")}>Ok</Button>
                            </Text>
                           
                        </PageContent>
                    </Col>
                </Row>
            </Container>
         
        </ContainerLandpage>
    );
}