import React, { useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
 
import ToggleTab from 'components/Landpage/Toggle/Tab'
import HowWorksItem from './Item'

import {
    HowWorksContainer,
    HowWorksDecoration,
    HowWorksTitleContent,
    HowWorksTitle,
    HowWorksText
} from './styled'

import LockIcon from '@mui/icons-material/Lock';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

export default function HowWorks(){

    const [activeTab, setActive] = useState(0)
 
    const optionsSchedule = [
        {
            icon: <ThumbUpOffAltIcon color="white" sx={{width: 32, height: 32}} />,
            subtitle: 'Simple',
            text: 'We take care of everything for you, give us your goals and we do the rest.'
        },
        {
            icon: <LocalShippingIcon color="white" sx={{width: 32, height: 32}} />,
            subtitle: 'Fast',
            text: 'In a few clicks, you will be visible to thousands of visitors every day.'
        },
        {
            icon: <LockIcon color="white" sx={{width: 32, height: 32}} />,
            subtitle: 'Secure',
            text: 'Pay online securely thanks to our payment module available for all credit cards.'
        }
    ]

    return ( 
        <> 
            <HowWorksContainer id="solution">
                {/* <HowWorksDecoration /> */}
                <Container>
                    <Row>
                        <Col>
                            <HowWorksTitleContent>
                                <HowWorksTitle>A turnkey solution</HowWorksTitle>
                                <HowWorksText>Thanks to our online advertising tools, you can in a few clicks share your website anywhere in the world or target only certain countries.</HowWorksText>
                            </HowWorksTitleContent>
                            {/* <ToggleTab options={tabOptions} active={activeTab} onClick={setActive} />  */}
                        </Col>
                    </Row>
                    <Row>
                        {
                            activeTab === 0 ? 
                                optionsSchedule.map((item, key) => 
                                    <Col key={key} md={{ size:4 }}>
                                        <HowWorksItem { ...item }  variant={key} />
                                    </Col>
                                )
                            : null
                        }  
                    </Row>
                </Container>
            </HowWorksContainer>
        </>
    );
}