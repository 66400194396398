import React from "react";  
 
import { 
    CardBanner,
    CardBannerTitle,
    CardBannerText, 
    CardBannerDecoration
} from "./styled";

import Button from "components/Form/Button";
import { scrollToSection } from "utils";

export default function CardSelect(){ 

    return ( 
        <> 
            <CardBanner>
                <CardBannerTitle>Pub<b>Click</b> Marketing</CardBannerTitle>
                <CardBannerDecoration />
                <CardBannerText>Reference your site and advertise easily on the internet</CardBannerText> 
                <div>
                    <Button color="secondary" onClick={() => scrollToSection('solution')}>
                        Read more
                    </Button>
                </div>
            </CardBanner> 
        </> 
    );
}