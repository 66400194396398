import React from "react"; 

import { Row, Col, Container } from 'reactstrap';  

import Item from './Item'

import {
    MoreInfosContainer,
    MoreInfosDecoration,
    MoreInfosTitle,
    MoreInfosText
} from './styled'

import AdsClickIcon from '@mui/icons-material/AdsClick';
import GoogleIcon from '@mui/icons-material/Google';
import GroupIcon from '@mui/icons-material/Group';

export default function MoreInfos(){ 
    
    const moreInfos = [
        { title: 'Online classified ad', text:'We may create online classifieds for you on different platforms.',  icon: <AdsClickIcon color="black" sx={{width: 48, height: 48}} /> },
        { title: 'Forums', text:'Access to the forums was often reserved for members, we can insert your links for you.',   icon: <GroupIcon color="black" sx={{width: 48, height: 48}} /> },
        { title: 'SEO and Google SEO', text:'We ensure an SEO on the first page of google according to your keywords.',   icon: <GoogleIcon color="black" sx={{width: 48, height: 48}} /> },
    ]

    return ( 
        <> 
            <MoreInfosContainer id="services"> 
                {/* <MoreInfosDecoration /> */}
                <Container>
                    <Row>
                        <Col> 
                            <MoreInfosTitle>
                                Services tailored to your needs
                            </MoreInfosTitle>
                            <MoreInfosText>
                                We offer various services to advertise you on the internet. Thanks to innovative technology, we can publish your site and reference it on hundreds of platforms.
                            </MoreInfosText> 
                        </Col>
                    </Row>
                    {
                        moreInfos.map((item, key) =>  
                            <Item key={key} variant={key} {...item} secondary={false}/>
                        )
                    }
                </Container>
            </MoreInfosContainer>
        </>
    );
}